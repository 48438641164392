import React, { useState } from "react"
import Layout, { MyLocationEnum } from "../components/Layout"
import SEO from "../components/Seo"
import styled from 'styled-components'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import NotFoundSVG from '../components/Svg/NotFoundImage'

const NotFoundPage = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query NotFoundImage {
        file(relativePath: { eq: "kjallari_404_mynd.jpg" }) {
          childImageSharp {
            fluid( maxWidth: 2000, quality: 60 ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <Layout location={location} myLocation={MyLocationEnum._404LostAndAlone}>
      <SEO title="404: Not found" />
      <Container>
        <NotFoundSVG/>
        <MyGatsbyImageWrapper
          durationFadeIn={100}
          fluid={data.file.childImageSharp.fluid} 
          objectPosition="top center"
        />
      </Container>
    </Layout>
  )
}

const Container = styled.div`
	position: relative;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	perspective: 2000px;
	pointer-events: auto;
`

const MyGatsbyImageWrapper = styled(Img)`
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /*width: 100%;*/
  z-index: -1;
  position: absolute !important;
`

export default NotFoundPage
